// src/components/Login.js
import React, {useState} from 'react';
import Cookies from 'js-cookie';
import Config from "./core/config";
import {useNavigate} from "react-router-dom";

const apiUrl = Config.apiUrl;
const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState(null);

    const navigate = useNavigate();

    const handleLogin = async () => {
        try {

            if (!email || email === '') {
                setLoginError('Informe o e-mail.');
                return;
            }

            if (!password || password === '') {
                setLoginError('Informe a senha.');
                return;
            }


            const basicAuthToken = btoa(`${email}:${password}`); // Cria o token Basic Auth

            const response = await fetch(`${apiUrl}/api/users/login`, {
                method: 'GET',
                headers: {
                    'Authorization': `Basic ${basicAuthToken}`
                },
            });

            if (response.ok) {
                const userData = await response.json();

                Cookies.set('user', JSON.stringify(userData), {expires: 7});
                Cookies.set('userUUID', userData.uuid, {expires: 7});
                navigate('/app');
                setLoginError(null);
                window.location.reload();
            } else {
                setLoginError( 'E-mail ou senha inválidos');
            }
        } catch (error) {
            console.error('Erro ao fazer login:', error);
            setLoginError('Erro ao fazer login. Tente novamente mais tarde.');
        }
    };

    return (
        <div style={{width: '250px', margin: 'auto'}}>
            <h2>Login</h2>
            <form>

                <div style={{marginTop: '5px', alignItems: 'center'}}>
                    <div style={{width: '180px'}}>Login:</div>
                    <input
                        style={{width: '250px'}}
                        type="text"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>

                <div style={{marginTop: '5px', alignItems: 'center'}}>
                    <div style={{width: '180px'}}>Senha:</div>
                    <input
                        style={{width: '250px'}}
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <div style={{marginTop: '5px', alignItems: 'center'}}>
                    <button style={{width: '250px'}} type="button" onClick={handleLogin}>
                        Acessar
                    </button>
                </div>
            </form>
            {loginError && <p style={{color: 'red'}}>{loginError}</p>}
        </div>
    );
};

export default Login;
