import React, {useEffect, useRef, useState} from 'react';
import '../../css/App.css';
import ActionButtonsManagerView from "./ActionButtonsManagerView";

const TextInputModal = ({label, value, onClose, onConfirm, messageWhenEmpty, removeSpecialCharacters}) => {
    const [textInput, setTextInput] = useState(value);
    const inputTextRef = useRef(null);

    useEffect(() => {
        const handleBackButton = (event) => {
            event.preventDefault();
            onClose();
        };
        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', handleBackButton);

        return () => {
            window.removeEventListener('popstate', handleBackButton);
        };
    }, []);

    const handleInputChange = (event) => {
        let newString = event.target.value.toUpperCase();
        if(removeSpecialCharacters) {
            newString = newString.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        }
        setTextInput(newString);
    };

    const handleSubmit = () => {
        if(messageWhenEmpty !== undefined && (textInput === undefined || textInput.trim() === '')) {
            alert(messageWhenEmpty);
            if(inputTextRef.current !== null) {
                inputTextRef.current.focus();
                inputTextRef.current.select();
            }
            return;
        }
        onConfirm(textInput);
        setTextInput('');
        onClose();
    };

    useEffect(() => {
        if(inputTextRef.current !== null) {
            inputTextRef.current.focus();
            inputTextRef.current.select();
        }
    }, []);

    const handleInputKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSubmit();
        }
    };

    return (

        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div>{label}</div>
            <input
                ref={inputTextRef}
                type="text"
                style={{width: '100%'}}
                value={textInput}
                onChange={handleInputChange}
                maxLength={300}
                onKeyDown={handleInputKeyDown}
            />

            <ActionButtonsManagerView>
                <button onClick={onClose} className="back-button">Cancelar</button>
                <button onClick={handleSubmit}>Confirmar</button>
            </ActionButtonsManagerView>

        </div>
        </div>

    );
};

export default TextInputModal;