import React, {useEffect, useRef, useState} from 'react';

import {useRecoilState} from "recoil";
import Config from "../core/config";
import Cookies from "js-cookie";
import {loadingAtom} from "../atoms";
import {makeRequest} from "../utils/httpRequest";
import searchImg from "../img/customer_auto_filling_facilitoy_logo.png"

const CustomerAutoFilling_InvalidLink = (props, {onCustomerAdded}) => {

    return (
        <div>
            <div style={{textAlign: 'center'}}><h2>Ficha Cadastral</h2></div>
                <div style={{textAlign: 'center'}}> <img src={searchImg} alt="Logo"/></div>
            <div style={{marginTop: '10px', alignItems: 'center', display: 'flex'}}>
                <div style={{textAlign: 'center', width:'100%'}}>Link inválido!</div>

            </div>
        </div>
    );
};

export default CustomerAutoFilling_InvalidLink;

