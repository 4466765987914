import React, {useState, useEffect, useRef} from 'react';
import DatePicker from "react-datepicker";
import {
    findProductsByQueryString,
    findSalesByStatus
} from "../../utils/requestUtils";
import Config from "../../core/config";
import {useRecoilState} from "recoil";
import {loadingAtom} from "../../atoms";
import ActionButtonsManagerView from "../common/ActionButtonsManagerView";
import {formatDateString, formatDateTimeString} from "../../utils/utils";
import 'react-datepicker/dist/react-datepicker.css';
import eyeIcon from '../../img/icons8-eye-24.png';
import SaleManagerSeeItems from "./SaleManagerSeeItems";
import Cookies from "js-cookie";


const apiUrl = Config.apiUrl;
const SalesListItems = () => {
    const [productQuantityList, setProductQuantityList] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [queryString, setQueryString] = useState('');
    const [, setLoading] = useRecoilState(loadingAtom);

    async function makeQuery() {
        try {
            setLoading(true);
            const newStartDate = startDate ? startDate : new Date(1900, 0, 1);
            const newEndDate = endDate ? endDate : new Date(2100, 0, 1);
            let sales = await findSalesByStatus(newStartDate, newEndDate, -1);


            const grouped = {};


            sales.forEach(sale => {
                sale.saleItems.forEach(item => {
                    if (!grouped[item.product.id]) {
                        grouped[item.product.id] = 0;
                    }
                    grouped[item.product.id] += item.quantity;
                });
            });

            console.log(JSON.stringify(grouped));
            const products = await findProductsByQueryString();
            let productQuantityList_ = Object.keys(grouped).map(productId => {
                const product = products.find(prod => prod.id == productId);
                return {
                    productName: product.name,
                    quantity: grouped[productId]
                };
            });

            if(queryString !== undefined && queryString.trim() !== '') {
                productQuantityList_ = productQuantityList_.filter((p) =>
                    p.productName &&  p.productName.toUpperCase().includes(queryString)
                );
            }

            productQuantityList_ = productQuantityList_.sort((a, b) => b.quantity - a.quantity);

            setProductQuantityList(productQuantityList_);

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        makeQuery();
    }, []);

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    function removeSpecialChars(str) {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
    }
    const handleInputChange = (e) => {
        const string = removeSpecialChars(e.target.value).toUpperCase();
        setQueryString(string);
    };

    return (


        <>
            <div className="header-label">Consultar Pedidos</div>

            <div style={{marginTop: '5px', alignItems: 'center'}}>
                <div style={{marginTop: '5px', display: 'flex', alignItems: 'center'}}>
                    <DatePicker
                        className="datepicker"
                        selected={startDate}
                        onChange={handleStartDateChange}
                        isClearable
                        timeFormat="HH:mm"
                        dateFormat="dd/MM/yyyy HH:mm"
                    />
                    até
                    <DatePicker
                        className="datepicker"
                        selected={endDate}
                        onChange={handleEndDateChange}
                        dateFormat="dd/MM/yyyy"
                        isClearable
                    />
                </div>
                <div style={{marginTop: '5px', display: 'flex', alignItems: 'center'}}>
                    <input style={{width: '100%'}} type="text" value={queryString} onChange={handleInputChange}/>
                    <button onClick={makeQuery}>Consultar</button>
                </div>
            </div>

            <div style={{flexGrow: 1, overflowY: "auto"}}>
                <table style={{width: '100%'}}>
                    <colgroup>
                        <col style={{width: '120px'}}/>
                        <col style={{width: '400px'}}/>
                    </colgroup>
                    <thead>
                    <tr>
                        <th>Quantidade</th>
                        <th>Produto</th>
                    </tr>
                    </thead>
                    <tbody style={{overflowY: 'auto'}}>
                    {productQuantityList.map((productQuantity, index) => (
                        <tr key={index} style={{height: '41px'}}>
                            <td>
                                <div
                                    key={index}>{productQuantity.quantity}</div>
                            </td>
                            <td>
                                <div key={index}>{productQuantity.productName}</div>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                    {productQuantityList.length === 0 &&
                        <tfoot>
                        <tr>
                            <td colSpan={2}>
                                Nenhum item encontrado
                            </td>
                        </tr>
                        </tfoot>
                    }
                </table>
            </div>
        </>

    );
};

export default SalesListItems;
