import React, {useEffect, useState} from 'react';
import {
    adjustDateToBrowserTimeZone,
    areDatesEqual,
    formatDateString,
    formatDateTimeString, formatTimeString,
    getOnlyDate
} from "../../utils/utils";
import BarChart from "../chart/BarChart";
import '../../css/DashBoard.css';
import PieChart from "../chart/PieChart";
import {
    getProductTypeChartData,
    getSellerChartData,
    invalidateCache,
    lastSellerValuesStorage
} from "../../utils/requestUtils";
import {useRecoilState} from "recoil";
import {loadingAtom} from "../../atoms";
import refreshIcon from '../../img/icons8-refresh-20.png';


const DashBoard = () => {

    const Periods = {
        TODAY: "TODAY",
        CURRENT_WEEK: "CURRENT_WEEK",
        CURRENT_MONTH: "CURRENT_MONTH",
        LAST_MONTH: "LAST_MONTH"
    };

    const periodLabels = {
        [Periods.TODAY]: "hoje",
        [Periods.CURRENT_WEEK]: "semana atual",
        [Periods.CURRENT_MONTH]: "mês atual",
        [Periods.LAST_MONTH]: "mês anterior"
    };

    const periods = [Periods.LAST_MONTH, Periods.CURRENT_MONTH, Periods.CURRENT_WEEK, Periods.TODAY];
    const [currentPeriodIndex, setCurrentPeriodIndex] = useState(periods.indexOf(Periods.TODAY));

    const [loading, setLoading] = useRecoilState(loadingAtom);

    const [selectedPeriod, setSelectedPeriod] = useState(Periods.TODAY);

    const [startDate, setStartDate] = useState(getOnlyDate(new Date()));
    const [endDate, setEndDate] = useState(getOnlyDate(new Date()));

    const handleButtonClick = (period) => {
        setCurrentPeriodIndex(periods.indexOf(period));
        setSelectedPeriod(period);
    };


    const [totalValue, setTotalValue] = useState(0);
    const [salesBySeller, setSalesBySeller] = useState([]);
    const [salesByType, setSalesByType] = useState([]);

    const [showSalesBySellerTable, setShowSalesBySellerTable] = useState(false);
    const [showSalesByTypeTable, setShowSalesByTypeTable] = useState(false);


    useEffect(() => {
        setTotalValue(salesBySeller.reduce((total, sale) => total + sale.value, 0));
    }, [salesBySeller]);

    async function queryData() {
        const {startDate, endDate} = getDatesFromPeriod(periods[currentPeriodIndex]);
        let response = await getSellerChartData(startDate, endDate);

        let filteredData = response.filter((r) => {
            const date = getOnlyDate(adjustDateToBrowserTimeZone(new Date(r.date)));
            return date <= endDate && date >= startDate;
        });

        let groupedData = filteredData.reduce((acc, item) => {
            const label = item.label;
            const value = item.value;

            if (acc[label]) {
                acc[label] += value;
            } else {
                acc[label] = value;
            }

            return acc;
        }, {});

        const salesBySeller = Object.keys(groupedData).map(key => ({
            label: key,
            value: groupedData[key]
        })).sort((a, b) => {
            if (a.value > b.value) {
                return -1;
            }
            return 0;
        });

        setSalesBySeller(salesBySeller);

        response = await getProductTypeChartData(startDate, endDate);


        filteredData = response.filter((r) => {
            const date = getOnlyDate(adjustDateToBrowserTimeZone(new Date(r.date)));
            return date <= endDate && date >= startDate;
        });

        groupedData = filteredData.reduce((acc, item) => {
            const label = item.label;
            const value = item.value;

            if (acc[label]) {
                acc[label] += value;
            } else {
                acc[label] = value;
            }

            return acc;
        }, {});

        const salesByType = Object.keys(groupedData).map(key => ({
            label: key,
            value: groupedData[key]
        })).sort((a, b) => {
            if (a.value > b.value) {
                return -1;
            }
            return 0;
        });


        setSalesByType(salesByType.splice(0, 5));

    }

    function selectPeriod(selectedPeriod) {
        setLoading(true);
        queryData()
            .then(r => setLoading(false))
            .catch(error => {
                    alert("Ocorreu um erro ao consultar os dados. Por favor, tente novamente mais tarde.");
                    setLoading(false);
                }
            );
        const {startDate, endDate} = getDatesFromPeriod(selectedPeriod);
        setStartDate(startDate);
        setEndDate(endDate);
    }

    useEffect(() => {

        selectPeriod(selectedPeriod);

    }, [selectedPeriod]);

    function getDatesFromPeriod(period) {
        const today = getOnlyDate(new Date());
        let startDate, endDate;

        switch (period) {
            case Periods.TODAY:
                startDate = today;
                endDate = today;
                break;
            case Periods.CURRENT_WEEK:
                startDate = new Date(today);
                startDate.setDate(today.getDate() - today.getDay());
                endDate = new Date(today);
                endDate.setDate(today.getDate() + (6 - today.getDay()));
                break;
            case Periods.CURRENT_MONTH:
                startDate = new Date(today.getFullYear(), today.getMonth(), 1);
                endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
                break;
            case Periods.LAST_MONTH:
                startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
                endDate = new Date(today.getFullYear(), today.getMonth(), 0);
                break;
            default:
                throw new Error("Período inválido");
        }

        return {startDate, endDate};
    }

    const salesByTypeLabels = salesByType.map(sale => sale.label);
    const salesByTypeValues = salesByType.map(sale => sale.value);

    const salesBySellerLabels = salesBySeller.map(sale => sale.label);
    const salesBySellerValues = salesBySeller.map(sale => sale.value);

    async function refreshClickAction() {
        await invalidateCache();
        selectPeriod(selectedPeriod);
    }

    return (
        <>
            <div style={{
                display: "flex",
                flexDirection: "column",
                width: '100%',
            }}>
                <div style={{flexShrink: 0, display: 'fixed', padding: '5px'}}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                            backgroundColor: 'white',
                            zIndex: 1000
                        }}
                        className="header-label">
                        {periods.map((p) => {

                            const {startDate, endDate} = getDatesFromPeriod(p);

                            return (
                                <button
                                    key={p}
                                    onClick={() => handleButtonClick(p)}
                                    style={{
                                        backgroundColor: selectedPeriod === p ? '#567ebb' : 'gray',
                                        color: 'white',
                                        border: 'none',
                                        padding: '10px',
                                        margin: '5px',
                                        cursor: 'pointer',
                                        width: '100%'
                                    }}
                                >
                                    {periodLabels[p]}
                                </button>
                            )
                        })}
                    </div>

                    {lastSellerValuesStorage &&
                        <div style={{width: '100%', display: 'flex', flexDirection:'row', alignItems: "center",
                            fontSize: '1rem'}}>
                            Última Atualização: {formatDateTimeString(lastSellerValuesStorage)}
                            <button style={{padding:0, backgroundColor:'white'}} onClick={refreshClickAction}>
                                <img src={refreshIcon} alt="Home" style={{width: '20px', height: '20px'}}/>
                            </button>
                        </div>
                    }
                    <div style={{
                        marginTop: '10px', display: "flex", flexDirection: "row", alignItems: "center", gap: '5px'
                    }}>

                        <div style={{
                            fontSize: '1.5rem'
                        }}>Vendas
                        </div>
                        <div style={{
                            fontSize: '1rem'
                        }}>{formatDateString(startDate)} {areDatesEqual(startDate, endDate) ? '' : ' à ' + formatDateString(endDate)}</div>



                    </div>

                    <div
                        style={{display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '10px'}}
                        className="header-label">
                        R$ {totalValue.toLocaleString('pt-BR', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })}
                    </div>
                </div>

                <div style={{flexGrow: 1, overflowY: "auto", height: 'calc(100svh - 270px)'}}>

                    <div style={{marginTop: '10px', backgroundColor: '#f6f5f5', padding: '5px', paddingRight: '10px'}}>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                            <div style={{fontSize: '1.5rem'}}>Vendas por Vendedor</div>
                        </div>
                        <BarChart labels={salesBySellerLabels} dataValues={salesBySellerValues}/>
                        <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
                            <button style={{
                                backgroundColor: showSalesBySellerTable ? '#567ebb' : 'gray',
                                fontSize: '14px'
                            }}
                                    onClick={() => setShowSalesBySellerTable(!showSalesBySellerTable)}
                            >
                                {showSalesBySellerTable ? 'Ocultar detalhes' : 'Mostrar detalhes'}
                            </button>
                        </div>
                        <div
                            className={`table-container ${showSalesBySellerTable ? 'fade-in' : 'fade-out'}`}>
                            <table style={{width: '100%'}}>
                                <colgroup>
                                    <col style={{width: '400px'}}/>
                                    <col style={{width: '200px'}}/>
                                </colgroup>
                                <thead>
                                </thead>
                                <tbody style={{overflowY: 'auto'}}>
                                {salesBySeller.map((data, index) => (
                                    <tr key={index}>
                                        <td>
                                            <div key={index}>{data.label}</div>
                                        </td>
                                        <td>
                                            <div key={index}
                                                 style={{textAlign: "right"}}>R$ {data.value.toLocaleString('pt-BR', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            })}</div>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                                {salesBySeller.length === 0 &&
                                    <tfoot>
                                    <tr>
                                        <td colSpan={5}>
                                            Nenhum item encontrado
                                        </td>
                                    </tr>
                                    </tfoot>
                                }
                            </table>
                        </div>
                    </div>

                    <div style={{marginTop: '10px', padding: '5px', paddingRight: '10px'}}>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                            <div style={{fontSize: '1.5rem'}}>Vendas por Tipo</div>
                        </div>
                        <PieChart labels={salesByTypeLabels} dataValues={salesByTypeValues}/>
                        <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
                            <button style={{
                                backgroundColor: showSalesByTypeTable ? '#567ebb' : 'gray',
                                fontSize: '14px'
                            }}

                                    onClick={() => setShowSalesByTypeTable(!showSalesByTypeTable)}
                            >
                                {showSalesByTypeTable ? 'Ocultar detalhes' : 'Mostrar detalhes'}
                            </button>
                        </div>
                        <div
                            className={`table-container ${showSalesByTypeTable ? 'fade-in' : 'fade-out'}`}>
                            <table style={{width: '100%'}}>
                                <colgroup>
                                    <col style={{width: '400px'}}/>
                                    <col style={{width: '200px'}}/>
                                </colgroup>
                                <thead>
                                </thead>
                                <tbody style={{overflowY: 'auto'}}>
                                {salesByType.map((data, index) => (
                                    <tr key={index}>
                                        <td>
                                            <div key={index}>{data.label}</div>
                                        </td>
                                        <td>
                                            <div key={index}
                                                 style={{textAlign: "right"}}>R$ {data.value.toLocaleString('pt-BR', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            })}</div>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                                {salesByType.length === 0 &&
                                    <tfoot>
                                    <tr>
                                        <td colSpan={5}>
                                            Nenhum item encontrado
                                        </td>
                                    </tr>
                                    </tfoot>
                                }
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DashBoard;
