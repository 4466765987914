import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {
    findCustomersByQueryString,
    findPendingSalesByCustomerId, findPendingSalesByCustomerIdList,
    findProductsByQueryString,
    getCustomerById, insertSale
} from "../../utils/requestUtils";
import Config from "../../core/config";
import {useRecoilState} from "recoil";
import {loadingAtom} from "../../atoms";
import Cookies from "js-cookie";
import {useNavigate, useParams} from "react-router-dom";
import ConfirmButton from "../common/ConfirmButton";
import ActionButtonsManagerView from "../common/ActionButtonsManagerView";
import {makeRequest} from "../../utils/httpRequest";
import {confirmAction} from "../../utils/utils";

const apiUrl = Config.apiUrl;
const FinishSaleItems = () => {

    const {customerIdsParam} = useParams();

    const [customers, setCustomers] = useState([]);
    const [originalSales, setOriginalSales] = useState([]);
    const [sales, setSales] = useState([]);

    const [products, setProducts] = useState([]);
    const [originalProducts, setOriginalProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [queryString, setQueryString] = useState('');
    const inputQueryString = useRef(null);
    const [loading, setLoading] = useRecoilState(loadingAtom);
    const navigate = useNavigate();
    const [totalValue, setTotalValue] = useState(undefined);

    async function updateSales(customerIds) {
        const products = await findProductsByQueryString('');
        let sales = [];

        for (const id of customerIds) {
            const sale = originalSales.filter((s) => s.customer.id === id);
            sales = sales.concat(sale);
        }

        const productQuantities = {};

        sales.forEach(sale => {
            sale.saleItems.forEach(item => {
                if (productQuantities[item.product.id+(item.productName !== undefined ? item.productName : '')]) {
                    productQuantities[item.product.id+(item.productName !== undefined ? item.productName : '')].quantity += item.quantity;
                } else {
                    productQuantities[item.product.id+(item.productName !== undefined ? item.productName : '')] = {
                        id: item.product.id,
                        productName: item.productName,
                        salePrice: item.product.salePrice,
                        quantity: item.quantity
                    };
                }
            });
        });

        console.log(JSON.stringify(productQuantities));
        products.forEach((item, index) => {
            if (!productQuantities[item.id+item.name]) {
                productQuantities[item.id+item.name] = {
                    id: item.id,
                    productName: item.name,
                    salePrice: item.salePrice,
                    quantity: 0,
                    order: index
                };
            }
        });

        let productList = [];

        for (const id in productQuantities) {
            if (productQuantities.hasOwnProperty(id)) {
                const productQuantity = productQuantities[id];
                const product = {
                    id: productQuantity.id,
                    companyId: 0,
                    code: 0,
                    name: productQuantity.productName,
                    salePrice: productQuantity.salePrice,
                    category: '',
                    quantity: productQuantity.quantity,
                    order: productQuantity.order
                };

                productList.push(product);
            }
        }

        productList = productList.sort((a, b) => {
            if (a.order < b.order) {
                return -1;
            }
            if (a.order > b.order) {
                return 1;
            }
            return 0;
        });

        let newProductList = productList.filter(p => p.quantity > 0 || p.quantity < 0);
        newProductList = newProductList.concat(productList.filter(p => p.quantity === undefined || p.quantity === 0));

        console.log(JSON.stringify(newProductList));
        await calculateTotalValue(newProductList);
        setSales(sales);
        setOriginalProducts(newProductList);
        setProducts(JSON.parse(JSON.stringify(newProductList)));
        setFilteredProducts(JSON.parse(JSON.stringify(newProductList)));
    }

    async function makeQuery() {
        try {
            const customerIds = customerIdsParam.split(",").map(Number);
            let newCustomers = [];

            for (const id of customerIds) {
                const customer = await getCustomerById(id);
                customer.checked = true;
                newCustomers = newCustomers.concat(customer);
            }

            const ids = newCustomers.filter(c => c.checked === true).map(c => c.id);
            const sales = await findPendingSalesByCustomerIdList(ids);
            setOriginalSales(sales);
            setCustomers(newCustomers);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    function compareArrays(array1, array2) {
        const differences = [];

        const productMap = new Map();

        array1.forEach(product => {
            const key = product.id + product.name;
            productMap.set(key, product);
        });

        array2.forEach(product => {
            const key = product.id + product.name;
            const productInArray1 = productMap.get(key);

            if (productInArray1) {
                if (product.quantity !== productInArray1.quantity) {
                    const difference = {
                        id: product.id,
                        name: product.name,
                        quantity: product.quantity - productInArray1.quantity
                    };
                    differences.push(difference);
                }
            }
        });

        return differences;
    }

    useEffect(() => {
        setLoading(true);
        makeQuery();
    }, []);

    async function calculateTotalValue(products) {
        const newTotalValue = products.reduce((accumulator, currentProduct) => {
            const quantity = currentProduct.quantity ?? 0;
            if (quantity > 0 || quantity < 0) {
                return accumulator + (currentProduct.salePrice * quantity);
            }
            return accumulator;
        }, 0);
        setTotalValue(newTotalValue);
    }

    function removeSpecialChars(str) {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
    }

    const handleInputChange = (e) => {
        let string = removeSpecialChars(e.target.value).toUpperCase();
        setQueryString(string);
    };
    useEffect(() => {
        const filteredProducts = products.filter((f) => removeSpecialChars(f.name).toUpperCase().includes(queryString));
        setFilteredProducts(JSON.parse(JSON.stringify(filteredProducts)));
    }, [queryString]);

    const doConfirm = async () => {
        const checkedCustomers = customers.filter(c => c.checked === true)
        if (checkedCustomers.length === 0) {
            alert('Nenhuma comanda selecionada.');
            return;
        }

        /* eslint-disable no-restricted-globals */
        if (!confirm('Essa operação não poderá ser revertida. Deseja confinuar?')) {
            return;
        }
        const userUUID = Cookies.get('userUUID');
        const urlReceipt = apiUrl + '/api/receipts/list';
        await makeRequest(urlReceipt, 'POST', {
            'Content-Type': 'application/json',
            'Useruuid': userUUID,
        }, JSON.stringify(checkedCustomers));

        const differences = compareArrays(originalProducts, products);

        const saleItemsWithDifferences = differences
            .map((p) => {
                return {product: p, productName: p.name, quantity: p.quantity};
            });

        console.log('saleItems: '+JSON.stringify(saleItemsWithDifferences));

        if(saleItemsWithDifferences.length > 0) {
            const saleWithDifferences = {};

            saleWithDifferences.customerName = 'DIF-'+checkedCustomers[0].name;
            saleWithDifferences.saleItems = saleItemsWithDifferences;
            saleWithDifferences.saleItems.forEach(s => s.status = 2);
            await insertSale(saleWithDifferences);
        }

        const salesId = sales.map(s => s.id);
        const urlPay = apiUrl + '/api/sales/update-status-payed';
        await makeRequest(urlPay, 'PUT', {
            'Content-Type': 'application/json',
            'Useruuid': userUUID,
        }, JSON.stringify(salesId));

        let urlToGo = '/app';
        const customersUnchecked = customers.filter(c => c.checked !== true);
        if (customersUnchecked.length > 0) {
            const idsString = customersUnchecked.map(c => c.id).join(',');
            customersUnchecked.forEach(c => c.checked = true);
            setCustomers(customersUnchecked);
            urlToGo = `/sale/finishitems/${idsString}`;
        }
        navigate(urlToGo);
    };

    const onConfirm = async () => {
        setLoading(true);
        await doConfirm();
        setLoading(false);
    }

    const doPrint = async () => {
        const checkedCustomers = customers.filter(c => c.checked === true)
        if (checkedCustomers.length === 0) {
            alert('Nenhuma comanda selecionada.');
            return;
        }

        const userUUID = Cookies.get('userUUID');

        const salesId = sales.map(s => s.id);

        const urlPay = apiUrl + '/api/sales/print-bill';
        await makeRequest(urlPay, 'PUT', {
            'Content-Type': 'application/json',
            'Useruuid': userUUID,
        }, JSON.stringify(salesId));

    };

    const onPrint = async () => {
        setLoading(true);
        await doPrint();
        setLoading(false);
    }

    const onCheckBoxChange = (index) => {
        const updatedCustomers = [...customers];
        updatedCustomers[index].checked = !updatedCustomers[index].checked;
        setCustomers(updatedCustomers);
    };

    useEffect(() => {
        setLoading(true);
        if(customers.length > 0) {
            updateSales(customers.filter(c => c.checked === true).map(c => c.id)).then(r => setLoading(false));
        }
    }, [customers]);

    useEffect(() => {
        calculateTotalValue(products);
    }, [products]);

    return (
        <>
            <div className="header-label">Fechar Comandas</div>


            {customers.map((customer, index) => (
                <div key={index} style={{display: "flex", alignItems: "center"}}>
                    <input type="checkbox"
                           checked={customer.checked}
                           onChange={() => onCheckBoxChange(index)}/>
                    {customer.name}
                </div>
            ))}

            <div
                style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}
                className="input-container">
                <input style={{width: '100%'}} ref={inputQueryString} type="text" value={queryString}
                       onChange={handleInputChange} onFocus={(event) => event.target.select()}/>
                <button id="clearButton" onClick={() => {
                    setQueryString('');
                    inputQueryString.current.focus();
                }}>X
                </button>
            </div>
            <table style={{width: '100%'}}>
                <thead>
                <tr>

                    <th>
                        Nome
                    </th>
                    <th>
                        Preço
                    </th>
                    <th/>
                    <th/>
                    <th/>
                </tr>
                </thead>
                <tbody style={{overflowY: 'auto'}}>
                {filteredProducts.map((product, index) => (
                    <tr key={index} style={{backgroundColor: product.quantity > 0 ? 'var(--warning-color)' : product.quantity < 0 ? 'var(--error-color)' :  'white'}}>
                        <td>
                            <div key={index}>{product.name}</div>
                        </td>
                        <td>
                            <div style={{textAlign: 'right'}}>{product.salePrice.toLocaleString('pt-BR', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}
                            </div>
                        </td>
                        <td>
                            <button className="remove-button" onClick={async () => {
                                setProducts((prevItems) =>
                                    prevItems.map((item, i) =>
                                        item.id === product.id && (!item.name || item.name === product.name) ? {
                                            ...item,
                                            quantity: (item.quantity ? item.quantity : 0) - 1,
                                        } : item
                                    )
                                );
                                setFilteredProducts((prevItems) =>
                                    prevItems.map((item, i) =>
                                        i === index ? {
                                            ...item,
                                            quantity: (item.quantity ? item.quantity : 0) - 1,
                                        } : item
                                    )
                                );
                            }}>-
                            </button>
                        </td>
                        <td>
                            {product.quantity ? product.quantity : 0}
                        </td>
                        <td>
                            <button onClick={async () => {

                                setProducts((prevItems) =>
                                    prevItems.map((item, i) =>
                                        item.id === product.id && (!item.name || item.name === product.name) ? {
                                            ...item,
                                            quantity: (item.quantity ? item.quantity : 0) + 1,
                                        } : item
                                    )
                                );
                                setFilteredProducts((prevItems) =>
                                    prevItems.map((item, i) =>
                                        i === index ? {
                                            ...item,
                                            quantity: (item.quantity ? item.quantity : 0) + 1,
                                        } : item
                                    )
                                );

                            }}>+
                            </button>
                        </td>

                    </tr>
                ))}
                </tbody>
                {filteredProducts.length === 0 &&
                    <tfoot>
                    <tr>
                        <td colSpan={5}>
                            Nenhum item encontrado
                        </td>
                    </tr>
                    </tfoot>
                }
            </table>
            <div style={{marginTop: '5px', alignItems: 'center'}}>

                <div className="total-label" style={{textAlign: 'right', fontSize: '30px', color: '#cc5545'}}>Valor
                    Total:
                    R$ {(totalValue !== undefined ? totalValue : 0).toLocaleString('pt-BR', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })}
                </div>
            </div>
            <ActionButtonsManagerView>
                <button onClick={onPrint} disabled={loading}>Imprimir</button>
                <button onClick={onConfirm} style={{backgroundColor: '#cc5545'}} disabled={loading}>Confirmar</button>
            </ActionButtonsManagerView>
        </>
    );
}


export default FinishSaleItems;
