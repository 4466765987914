import {makeRequest} from "./httpRequest";
import Config from "../core/config";
import {formatDateToAmericanFormat} from "./utils";
import Cookies from "js-cookie";
import {saveAs} from 'file-saver';

const {v4: uuidv4} = require('uuid');

const apiUrl = Config.apiUrl;


export async function findProductsByQueryString(query) {
    try {
        const productsInLocalStorageJson = localStorage.getItem('products');
        if (productsInLocalStorageJson !== undefined) {
            const productssInLocalStorage = JSON.parse(productsInLocalStorageJson);
            if (productssInLocalStorage !== undefined && productssInLocalStorage !== null && productssInLocalStorage.length > 0) {
                return productssInLocalStorage;
            }
        }
        const url = apiUrl + '/api/products/query=' + (query && query !== '' ? query : '');
        const response: Product[] = await makeRequest(url, 'GET',
            {'Useruuid': Cookies.get('userUUID')});
        localStorage.setItem('products', JSON.stringify(response));
        return response;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export async function findCustomersByQueryString(query) {
    try {
        const customerInLocalStorageJson = localStorage.getItem('customers');
        if (customerInLocalStorageJson !== undefined) {
            const customersInLocalStorage = JSON.parse(customerInLocalStorageJson);
            if (customersInLocalStorage !== undefined && customersInLocalStorage !== null && customersInLocalStorage.length > 0) {
                return customersInLocalStorage;
            }
        }
        const url = apiUrl + '/api/customers/query=' + (query && query !== '' ? (query) : '');
        const response = await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
        localStorage.setItem('customers', JSON.stringify(response));
        return response;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export async function findTablesByQueryString(query) {
    try {
        const tableInLocalStorageJson = localStorage.getItem('tables');
        if (tableInLocalStorageJson !== undefined) {
            const tablesInLocalStorage = JSON.parse(tableInLocalStorageJson);
            if (tablesInLocalStorage !== undefined && tablesInLocalStorage !== null && tablesInLocalStorage.length > 0) {
                return tablesInLocalStorage;
            }
        }
        const url = apiUrl + '/api/tables/query=' + (query && query !== '' ? (query) : '');
        const response = await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
        localStorage.setItem('tables', JSON.stringify(response));
        return response;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}


export async function getRentalItem(id) {
    try {
        const url = apiUrl + '/api/rentalItem/id=' + id;
        const response = await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
        return response;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export async function findRentalsByStringAndStatus(query, status) {
    try {
        if (!query) {
            query = '';
        }
        const url = apiUrl + '/api/productRented/findRentalsByStatus/query=' + query + '&status=' + status;
        const response = await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
        return response;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export async function findPendingRentalItemItemByProductAvailability(productId) {
    try {
        const url = apiUrl + '/api/rentalItem/productId=' + productId;
        const response = await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
        return response;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}


export async function getProductById(id) {

    try {
        const url = apiUrl + '/api/products/id=' + id;
        const response = await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
        return response;
    } catch (error) {
        console.error('Error fetching data:', error);
    }

}

export async function getReceivableById(id) {

    try {
        const url = apiUrl + '/api/receivables/id=' + id;
        const response = await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
        return response;
    } catch (error) {
        console.error('Error fetching data:', error);
    }

}

export async function getCustomerById(id) {

    try {
        const customers = await findCustomersByQueryString('');
        return customers.find(c => c.id === id);
    } catch (error) {
        console.error('Error fetching data:', error);
    }

}

export async function getSupplierById(id) {

    try {
        const url = apiUrl + '/api/suppliers/id=' + id;
        const response = await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
        return response;
    } catch (error) {
        console.error('Error fetching data:', error);
    }

}

export async function getPaymentById(id) {

    try {
        const url = apiUrl + '/api/payments/id=' + id;
        const response = await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
        return response;
    } catch (error) {
        console.error('Error fetching data:', error);
    }

}

export async function getWaitingListById(id) {

    try {
        const url = apiUrl + '/api/waitingList/id=' + id;
        const response = await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
        return response;
    } catch (error) {
        console.error('Error fetching data:', error);
    }

}

export async function getProductRentalPriceByProductId(productId) {

    try {
        const url = apiUrl + '/api/product_rental_prices/productId=' + productId;
        const response = await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
        return response;
    } catch (error) {
        console.error('Error fetching data:', error);
    }

}

export async function getAllPaymentMethods() {

    try {
        const url = apiUrl + '/api/paymentMethods';
        const response = await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
        return response;
    } catch (error) {
        console.error('Error fetching data:', error);
    }

}

export async function getPendingWaitingList(productId) {
    const url = `${apiUrl}/api/waitingList/productId=${productId ? productId : 0}`;
    return await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
}

export function getProductAvailability(productId, rentalId, days) {
    const url = `${apiUrl}/api/productAvailabilities/productId=${productId ? productId : 0}&rentalId=${rentalId}&days=${days}&currentDate=${formatDateToAmericanFormat(new Date())}`;
    return makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
}

export async function getProductAvailabilityFilterByQuantity(productId, rentalId, currentDate, days, quantity) {
    const url = `${apiUrl}/api/productAvailabilities/productId=${productId ? productId : 0}&rentalId=${rentalId}&days=${days}&currentDate=${formatDateToAmericanFormat(currentDate)}`;
    const availabilities = await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
    return availabilities.filter((a) => a.quantity >= quantity)
}

export function getProductAvailabilityPerDate(productId, rentalId, days, currentDate, startDate, endDate, quantity) {
    const url = `${apiUrl}/api/productAvailabilities/filterByDate/productId=${productId ? productId : 0}&rentalId=${rentalId}&days=${days}&currentDate=${formatDateToAmericanFormat(currentDate)}&startDate=${formatDateToAmericanFormat(startDate)}&endDate=${formatDateToAmericanFormat(endDate)}&quantity=${quantity}`;
    return makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
}

export async function customerKeyExists(customerKey) {
    const url = apiUrl + '/api/customerKey/customerKey=' + customerKey;
    return makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
}

export async function userUUIDExists() {
    const url = apiUrl + '/api/users/userUUID';
    return makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
}

export async function getRentalById(id) {

    try {
        const url = apiUrl + '/api/rentals/id=' + id;
        return await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
    } catch (error) {
        console.error('Error fetching data:', error);
    }

}

export async function getCompanyByUserUUID() {

    try {
        const url = apiUrl + '/api/companies/userUUID';
        return await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
    } catch (error) {
        console.error('Error fetching data:', error);
    }

}

export async function downloadContract(id, code) {
    try {
        const url = `${apiUrl}/api/contract/${id}`;
        const response = await fetch(url, {headers: {'Useruuid': Cookies.get('userUUID')}});
        const data = await response.blob();

        saveAs(data, 'contrato_' + code + '.pdf');

    } catch (error) {
        console.error('Erro ao obter PDF:', error);
    }
}

export async function downloadDeliveryStatement(id, code) {
    try {
        const url = `${apiUrl}/api/deliveryStatement?id=${id}`;
        const response = await fetch(url, {headers: {'Useruuid': Cookies.get('userUUID')}});
        const data = await response.blob();

        saveAs(data, 'termo_de_entrega_' + code + '.pdf');

    } catch (error) {
        console.error('Erro ao obter PDF:', error);
    }
}

export async function findCustomerPendingSale(customerString) {
    try {
        const url = apiUrl + '/api/sales/list-customer-pending-sale?customer-string=' + customerString;
        return await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export async function findPendingSalesByCustomerId(customerId) {
    try {
        const url = apiUrl + '/api/sales/list-pending-sales-by-customer?customer-id=' + customerId;
        return await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export async function findPendingSalesByCustomerIdList(ids) {
    try {
        const url = `${apiUrl}/api/sales/list-pending-sales-by-customer-id-list?id-list=${ids.join(',')}`;
        return await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}


export async function findSalesPrinted() {
    try {
        const url = apiUrl + '/api/sales/sales-printed';
        const response = await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
        return response;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}


export async function forceSalePrinting(ids) {
    try {
        const urlPay = apiUrl + '/api/sales/force-printing';
        await makeRequest(urlPay, 'PUT', {
            'Content-Type': 'application/json',
            'Useruuid': Cookies.get('userUUID'),
        }, JSON.stringify(ids));

    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export async function findSalesByStatus(startDate, endDate, status) {
    try {
        const url = `${apiUrl}/api/sales/find-by-date-status-payed?startDate=${formatDateToAmericanFormat(startDate)}&endDate=${formatDateToAmericanFormat(endDate)}&status=${status}`;
        const response = await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
        return response;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export async function insertSale(sale) {
    const userUUID = Cookies.get('userUUID');
    const url = apiUrl + '/api/sales';

    sale.uuid = uuidv4();
    await makeRequest(url, 'POST', {
        'Content-Type': 'application/json',
        'Useruuid': userUUID,
    }, JSON.stringify(sale));
}

export let lastSellerValuesStorage = undefined;

export async function invalidateCache() {
    lastSellerValuesStorage = undefined;
    for (let i = localStorage.length - 1; i >= 0; i--) {
        const key = localStorage.key(i);
        if (key.startsWith('sellerValues') || key.startsWith('productTypeValues')) {
            localStorage.removeItem(key);
        }
    }
}
export async function getSellerChartData(startDate, endDate) {
    try {
        if (lastSellerValuesStorage) {
            const now = new Date();

            const time1 = lastSellerValuesStorage.getTime();
            const time2 = now.getTime();

            const differenceInMillis = time2 - time1;

            const fiveMinutesInMillis = 5 * 60 * 1000;
            if (differenceInMillis < fiveMinutesInMillis) {
                const saleItemsJson = localStorage.getItem(`sellerValues_${formatDateToAmericanFormat(startDate)}_${formatDateToAmericanFormat(endDate)}`);
                if (saleItemsJson !== undefined) {
                    const saleItems = JSON.parse(saleItemsJson);
                    if (saleItems !== undefined && saleItems !== null && saleItems.length > 0) {
                        return saleItems;
                    }
                }
            } else {
                await invalidateCache();
            }
        }
        // const url = `${apiUrl}/api/sales/seller-chart-data?startDate=${formatDateToAmericanFormat(startDate)}&endDate=${formatDateToAmericanFormat(endDate)}`;
        const url = `${apiUrl}/api/sales/seller-chart-data`;
        const response = await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
        localStorage.setItem(`sellerValues_${formatDateToAmericanFormat(startDate)}_${formatDateToAmericanFormat(endDate)}`, JSON.stringify(response));
        lastSellerValuesStorage = new Date();
        return response;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

let lastProductTypeStorage = undefined;

export async function getProductTypeChartData(startDate, endDate) {
    try {
        if (lastProductTypeStorage) {
            const now = new Date();

            const time1 = lastProductTypeStorage.getTime();
            const time2 = now.getTime();

            const differenceInMillis = time2 - time1;

            const fiveMinutesInMillis = 5 * 60 * 1000;
            if (differenceInMillis < fiveMinutesInMillis) {
                const saleItemsJson = localStorage.getItem(`productTypeValues_${formatDateToAmericanFormat(startDate)}_${formatDateToAmericanFormat(endDate)}`);
                if (saleItemsJson !== undefined) {
                    const saleItems = JSON.parse(saleItemsJson);
                    if (saleItems !== undefined && saleItems !== null && saleItems.length > 0) {
                        return saleItems;
                    }
                }
            } else {
                await invalidateCache();
            }
        }
        // const url = `${apiUrl}/api/sales/seller-chart-data?startDate=${formatDateToAmericanFormat(startDate)}&endDate=${formatDateToAmericanFormat(endDate)}`;
        const url = `${apiUrl}/api/sales/product-type-chart-data`;
        const response = await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
        localStorage.setItem(`productTypeValues_${formatDateToAmericanFormat(startDate)}_${formatDateToAmericanFormat(endDate)}`, JSON.stringify(response));
        lastProductTypeStorage = new Date();
        return response;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}