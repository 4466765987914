import React, {useState, useEffect, useRef} from 'react';
import {
    findSalePendingSale,
    findSalesByQueryString,
    findSalesPrinted,
    forceSalePrinting
} from "../../utils/requestUtils";
import Config from "../../core/config";
import {useRecoilState} from "recoil";
import {loadingAtom} from "../../atoms";
import {useNavigate} from "react-router-dom";
import Cookies from "js-cookie";
import ConfirmButton from "../common/ConfirmButton";
import ActionButtonsManagerView from "../common/ActionButtonsManagerView";

const apiUrl = Config.apiUrl;
const SalesPrintedToPrintAgain = () => {
        const [sales, setSales] = useState([]);
        const [queryString, setQueryString] = useState('');
        const inputQueryString = useRef(null);
        const [, setLoading] = useRecoilState(loadingAtom);

        async function makeQuery(query) {
            try {
                setLoading(true);
                let sales = await findSalesPrinted();
                if(query.length > 0) {
                    sales = sales.filter(f => f.id === Number(query));
                }
                sales.forEach(c => c.checked = false);
                setSales(sales);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        }

        useEffect(() => {
            // if (inputQueryString.current) {
            //     inputQueryString.current.focus();
            // }
            makeQuery(queryString);
        }, [queryString]);

        function removeSpecialChars(str) {
            return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
        }

        const handleInputChange = (e) => {
            let string = removeSpecialChars(e.target.value).toUpperCase();
            setQueryString(string);
        };

        const onCheckBoxChange = (index) => {
            const updatedSales = [...sales];
            updatedSales[index].checked = !updatedSales[index].checked;
            setSales(updatedSales);
            setQueryString('');

        };

        return (
            <>
                <div className="header-label">Reimprimir Pedido</div>

                <div
                    style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}
                    className="input-container">
                    <input style={{width: '100%'}} ref={inputQueryString} type="text" value={queryString}
                           onChange={handleInputChange} onFocus={(event) => event.target.select()}/>
                    <button id="clearButton" onClick={() => {
                        setQueryString('');
                        inputQueryString.current.focus();
                    }}>X
                    </button>
                </div>
                <div style={{flexGrow: 1, overflowY: "auto"}}>
                    <table style={{width: '100%'}}>
                        <colgroup>
                            <col style={{width: '10px'}}/>
                            <col style={{width: '20px'}}/>
                            <col style={{width: '150px'}}/>
                            <col style={{width: '150px'}}/>
                        </colgroup>
                        <thead>
                        <tr>
                            <th/>
                            <th>Pedido</th>
                            <th>Comanda</th>
                            <th>Mesa</th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody style={{overflowY: 'auto'}}>
                        {sales.map((sale, index) => (
                            <tr key={index} style={{backgroundColor: sale.checked ? 'var(--warning-color)' : 'white'}}>
                                <td>
                                    <input type="checkbox"
                                           checked={sale.checked}
                                           onChange={() => onCheckBoxChange(index)}/>
                                </td>
                                <td>
                                    <div style={{textAlign:"center"}} key={index}>{sale.id}</div>
                                </td>
                                <td>
                                    <div key={index}>{sale.customer.name}</div>
                                </td>
                                <td>
                                    <div key={index}>{sale.table.name}</div>
                                </td>
                                <td>
                                    {sales.filter(c => c.checked === true).length === 0 &&
                                        <button onClick={async () => {
                                            await forceSalePrinting([sale.id]);
                                            setSales(sales.filter(s => s.id !== sale.id))
                                        }}>Reimprimir
                                        </button>
                                    }
                                </td>

                            </tr>
                        ))}
                        </tbody>
                        {sales.length === 0 &&
                            <tfoot>
                            <tr>
                                <td colSpan={5}>
                                    Nenhum item encontrado
                                </td>
                            </tr>
                            </tfoot>
                        }
                    </table>
                </div>
                {sales.filter(c => c.checked === true).length > 0 &&
                    <ActionButtonsManagerView>
                        <button onClick={async () => {
                            const ids = sales.filter(c => c.checked === true).map(c => c.id);
                            await forceSalePrinting(ids);
                            setSales(sales.filter(c => c.checked === false));
                        }}>Reimprimir
                        </button>
                    </ActionButtonsManagerView>
                }
            </>
        );
    }
;

export default SalesPrintedToPrintAgain;
