import './css/App.css';
import React, {useEffect, useRef, useState} from "react";
import {loadingAtom} from "./atoms";
import {useRecoilState} from 'recoil';
import Loading from "./core/Loading";
import Cookies from "js-cookie";
import {useNavigate} from "react-router-dom";
import {getCompanyByUserUUID, userUUIDExists} from "./utils/requestUtils";
import homeIcon from './img/icons8-home-24.png';


function Menu({children}) {

    const [currentLoading, setCurrentLoading] = useRecoilState(loadingAtom);

    const [logged, setLogged] = useState(false);
    const navigate = useNavigate();


    userUUIDExists()
        .then(r => setLogged(true))
        .catch(r => {
        if (r.statusCode === 404) {
            navigate('/login');
        }
        setLogged(false);

    });


    const [isMenuVisible, setMenuVisible] = useState(false);
    const menuRef = useRef(null);


    const [companyName, setCompanyName] = useState(null);
    useEffect(() => {

        async function fetchData() {
            try {
                const result = await getCompanyByUserUUID();
                setCompanyName(result.name);
            } catch (error) {
                console.error('Erro ao buscar os dados:', error);
            }
        }

        fetchData();

        const handleOutsideClick = (event) => {
            // Se o clique não foi dentro do menu, oculta o menu
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setMenuVisible(false);
            }
        };

        // Adiciona um ouvinte de evento global para cliques fora do menu
        document.addEventListener('click', handleOutsideClick);

        // Remove o ouvinte de evento quando o componente é desmontado
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []); // Executa apenas uma vez durante a montagem do componente

    const toggleMenu = () => {
        setMenuVisible(!isMenuVisible);
    };

    function handleExitClickButton() {
        Cookies.remove('userUUID');
        Cookies.remove('user');
        localStorage.removeItem('products');
        localStorage.removeItem('customers');
        localStorage.removeItem('tables');
        localStorage.removeItem('sale');
        navigate('/login');
    }

    const userCookies = Cookies.get('user');
    let isUserAdmin = false;
    if (userCookies) {
        const user = JSON.parse(userCookies);
        isUserAdmin = user.email === 'renanedpedroso@gmail.com';
    }

    if(!logged) {
        return <></>;
    }

    return (
        <div>
            {companyName}
            {currentLoading && (<Loading/>)}
            <div ref={menuRef} style={{
                zIndex: 1000,
                width: '100%',
                backgroundColor: "#333",
                display: "flex",
                flexWrap: "wrap",
                minHeight: "50px",
                alignItems: "center",
                justifyContent: 'space-between'
            }}>
                <div>
                    {/*<div*/}
                    {/*    onClick={(e) => {*/}
                    {/*        e.preventDefault();*/}
                    {/*        setMenuVisible(false);*/}
                    {/*        navigate('/app');*/}
                    {/*    }}*/}
                    {/*    className={'menu-button ' + (activeMenu === 'Inicio' ? 'active' : '')}*/}
                    {/*>*/}
                    {/*    <img src={homeIcon} alt="Home" style={{width: '20px', height: '20px'}}/>*/}
                    {/*</div>*/}
                </div>

                <div>
                    <div
                        onClick={() => {
                            toggleMenu();
                            handleExitClickButton();
                        }}
                        className="menu-button"
                    >
                        Sair
                    </div>
                </div>
            </div>
            {children}
        </div>
    );
}

export default Menu;
