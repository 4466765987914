export function formatCPF(value) {
    const numbers = value.replace(/\D/g, '');

    if (numbers.length >= 3) {
        let formattedCPF = numbers;
        const part1 = numbers.substring(0, 3);

        if (numbers.length <= 11) {
            const part2 = numbers.substring(3, 6);
            const part3 = numbers.substring(6, 9);
            const part4 = numbers.substring(9, 11);
            formattedCPF = `${part1}.${part2}.${part3}-${part4}`;
        }

        if (numbers.length <= 9) {
            const part2 = numbers.substring(3, 6);
            const part3 = numbers.substring(6);
            formattedCPF = `${part1}.${part2}.${part3}`;
        }


        if (numbers.length <= 6) {
            const part2 = numbers.substring(3);
            formattedCPF = `${part1}.${part2}`;
        }

        if (numbers.length <= 3) {
            formattedCPF = `${part1}`;
        }

        return formattedCPF;
    } else {
        return numbers;
    }
}

export function validateCPF(cpf) {
    cpf = cpf.replace(/\D/g, '');

    if (cpf.length !== 11 || /^(.)\1+$/.test(cpf)) {
        return false;
    }

    let sum = 0;
    let remainder;

    for (let i = 1; i <= 9; i++) {
        sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i);
    }

    remainder = (sum * 10) % 11;

    if (remainder === 10 || remainder === 11) {
        remainder = 0;
    }

    if (remainder !== parseInt(cpf.substring(9, 10))) {
        return false;
    }

    sum = 0;

    for (let i = 1; i <= 10; i++) {
        sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i);
    }

    remainder = (sum * 10) % 11;

    if (remainder === 10 || remainder === 11) {
        remainder = 0;
    }

    return remainder === parseInt(cpf.substring(10, 11));
}