import React, {useEffect, useRef, useState} from 'react';
import {useRecoilState} from "recoil";
import {loadingAtom} from "../atoms";
import Config from "../core/config";
import Cookies from "js-cookie";
import {formatPhoneNumber} from "../core/telephone";
import {validateEmail} from "../core/email";
import {useNavigate} from "react-router-dom";

import searchImg from "../img/customer_auto_filling_facilitoy_logo.png"

const CustomerAutoFilling_Contact = (props, {onCustomerAdded}) => {
    const {customerKeyValid} = props;
    const navigate = useNavigate();
    const currentCustomerAutoFilling = Cookies.get('customerAutoFilling');

    const inputTextPhone = useRef();
    const inputTextEmail = useRef();

    const newEntity = {
        id: undefined,
        name: '',
        address: '',
        addressNumber: '',
        addressComplement: '',
        neighborhood: '',
        cep: '',
        city: '',
        state: '',
        phone: '',
        email: '',
        instagram: '@',
        birthDate: undefined,
        rg: '',
        cpf: '',
        delivery: false,
        spaceBorneDelivery: false,
        howDidYouFindUs: '',
        referral: ''
    };


    const [formData, setFormData] = useState(currentCustomerAutoFilling ? JSON.parse(currentCustomerAutoFilling) : newEntity);

    useEffect(() => {
        if (inputTextPhone.current) {
            inputTextPhone.current.focus();
        }
        if (!customerKeyValid) {
            navigate(`/autocadastro/linkInvalido`);
        }
    }, []);

    const handleSaveButtonClick = async () => {

        try {
            if (validateInputs()) {
                await save();
            }
        } catch (error) {
            console.error('Error saving:', error);
            alert('Error ao salvar.');
        }
    };

    function validateInputs() {

        if (!formData.phone || formData.phone === '') {
            alert('Preencha o campo telefone.');
            if (inputTextPhone.current) {
                inputTextPhone.current.focus();
            }
            return false;
        }

        if (formData.email && formData.email !== '') {
            if (!validateEmail(formData.email)) {
                alert('E-mail inválido.');
                if (inputTextEmail.current) {
                    inputTextEmail.current.focus();
                }
                return false;
            }
        }

        return true;
    }

    async function save() {
        Cookies.set('customerAutoFilling', JSON.stringify(formData));
        navigate(`/autocadastro/crianca/0`);
    }


    const handleChange = (e) => {
        const {name, value, type, checked} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };


    function handleBackButtonClick() {
        navigate('/autocadastro/endereco')
    }

    return (
        <div>
            <div style={{textAlign: 'center'}}><h2>Ficha Cadastral - Contato</h2></div>

            <div style={{textAlign: 'center'}}><img src={searchImg} alt="Logo"/></div>

            <div style={{marginTop: '10px', alignItems: 'center'}}>
                <div style={{width: '180px'}}>Telefone:</div>
                <input ref={inputTextPhone} type="text" id="phone" value={formData.phone} name="phone" onChange={(e) => {
                    e.target.value = formatPhoneNumber(e.target.value);
                    handleChange(e);
                }}/>
            </div>

            <div style={{marginTop: '10px', alignItems: 'center'}}>
                <div style={{width: '180px'}}>Email:</div>
                <input ref={inputTextEmail}  type="text" style={{width: '300px'}} id="email" value={formData.email} name="email"
                       onChange={handleChange}/>
            </div>

            <div style={{marginTop: '10px', alignItems: 'center'}}>
                <div style={{width: '180px'}}>Instagram:</div>
                <input type="text" id="instagram" value={formData.instagram} name="instagram" onChange={(e) => {
                    if (!e.target.value.startsWith('@')) {
                        e.target.value = '@' + e.target.value;
                    }
                    handleChange(e);
                }}/>
            </div>

            <div style={{marginTop: '10px', alignItems: 'center'}}>
                <button onClick={handleBackButtonClick}>Voltar</button>
                <button onClick={handleSaveButtonClick}>Continuar</button>
            </div>


        </div>
    );
};

export default CustomerAutoFilling_Contact;
