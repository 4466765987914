import React from 'react';

const CentralizedView = (props) => {
    const {title, children} = props;

    const containerStyle = {
        height: 'calc(100vh - 66px)',
        backgroundColor: '#f2f2f2',
        paddingLeft:'10px',
        paddingRight:'10px',
    };

    const centralizedContainerStyle = {
        justifyContent: 'center',
    };

    const contentStyle = {
        marginTop: '10px',
        maxHeight: '580px',
        padding: '20px',
        borderRadius: '10px',
        border: '2px solid #ccc',
        backgroundColor: '#fff',
        display: 'inline-block',
    };

    const centralizedContentStyle = {
        position: 'relative',
        left: '50%',
        transform: 'translateX(-50%)',
    }
    const isSmallScreen = window.innerWidth <= 1000;

    const mergedContainerStyles = isSmallScreen ? containerStyle : { ...containerStyle, ...centralizedContainerStyle };
    const mergedContentStyles = isSmallScreen ? contentStyle : { ...contentStyle, ...centralizedContentStyle };
    return (


    <div className={"centralized-div1"}>
            <div className={"centralized-div-content"}>
                {children}
            </div>
        </div>
    );
};

export default CentralizedView;
