import React, {useEffect, useRef, useState} from 'react';
import {findProductsByQueryString} from "../../utils/requestUtils";
import Config from "../../core/config";
import {useRecoilState} from "recoil";
import {loadingAtom} from "../../atoms";
import Cookies from "js-cookie";
import {useNavigate} from "react-router-dom";
import ConfirmButton from "../common/ConfirmButton";
import ActionButtonsManagerView from "../common/ActionButtonsManagerView";
import {makeRequest} from "../../utils/httpRequest";
import {adjustDateToBrowserTimeZone, calculateDaysDifference, getOnlyDate} from "../../utils/utils";
import TextInputModal from "../common/TextInputModal";

const apiUrl = Config.apiUrl;
const SaleManagerItems = () => {
    const [products, setProducts] = useState([]);
    const [reload, setReload] = useState(false);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [queryString, setQueryString] = useState('');
    const inputQueryString = useRef(null);
    const [loading, setLoading] = useRecoilState(loadingAtom);
    const navigate = useNavigate();
    const saleCookie = localStorage.getItem('sale');
    const newSale = {saleItems: []};
    const [sale, setSale] = useState(saleCookie ? JSON.parse(localStorage.getItem('sale')) : newSale);
    const customerName = sale.customer !== undefined ? sale.customer.name : undefined;
    const [totalValue, setTotalValue] = useState(0);
    const [inputTextProductNameModalVisible, setInputTextProductNameModalVisible] = useState(false);
    const [inputTextCustomerNameModalVisible, setInputTextCustomerNameModalVisible] = useState(false);
    const [indexProductNoteChanging, setIndexProductNoteChanging] = useState(-1);


    async function makeQuery(query) {
        try {
            let productWithDifferentNameToAdd = [];
            setLoading(true);
            const products = await findProductsByQueryString(query);
            if (sale.saleItems !== undefined) {
                sale.saleItems.forEach((s) => {
                    for (let index = 0; index < products.length; index++) {
                        const p = products[index];
                        if (s.product.id === p.id) {
                            if (s.product.note !== p.note) {
                                const newProduct = {...p};
                                newProduct.note = s.product.note;
                                newProduct.quantity = s.quantity;
                                productWithDifferentNameToAdd = productWithDifferentNameToAdd.concat(newProduct);
                            } else {
                                p.quantity = s.quantity;
                            }

                        }
                    }
                });
            }


            for (let index = 0; index < productWithDifferentNameToAdd.length; index++) {
                const p = productWithDifferentNameToAdd[index];

                let indexToInsert = 0;
                for (let i = 0; i < products.length; i++) {
                    if (p.sort <= products[i].sort && p.name.includes(products[i].name)) {
                        indexToInsert = i;
                        break;
                    } else {
                        indexToInsert = i + 1;
                    }
                }
                products.splice(indexToInsert, 0, p);


            }

            setProducts(products);
            setFilteredProducts(JSON.parse(JSON.stringify(products)));
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    }

    useEffect(() => {
        // if (inputQueryString.current) {
        //     inputQueryString.current.focus();
        // }
        makeQuery('');
    }, [reload]);

    useEffect(() => {
        setLoading(true);
        localStorage.setItem('sale', JSON.stringify(sale));
        setLoading(false);
    }, [sale]);

    useEffect(() => {
        setLoading(true);
        const newTotalValue = products.filter(p => p.quantity !== 0).reduce((accumulator, currentProduct) => {
            const quantity = currentProduct.quantity ?? 0;
            return accumulator + (currentProduct.salePrice * quantity);
        }, 0);
        setTotalValue(newTotalValue);
        setLoading(false);
    }, [products]);


    useEffect(() => {
        const filteredProducts = products.filter((f) => removeSpecialChars(f.name).toUpperCase().includes(queryString));
        setFilteredProducts(JSON.parse(JSON.stringify(filteredProducts)));
    }, [queryString]);

    function removeSpecialChars(str) {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
    }

    const handleInputChange = (e) => {
        let string = removeSpecialChars(e.target.value).toUpperCase();
        setQueryString(string);
    };

    function doConfirm() {
        const saleItems = products
            .filter((p) => p.quantity !== undefined && p.quantity !== 0)
            .map((p) => {
                return {product: p, quantity: p.quantity};
            });
        if (saleItems.length === 0) {
            alert('Nenhum item foi adicionado.')
            return;
        }

        const newSale = {
            table: sale.table,
            customerName: sale.customerName,
            customer: sale.customer,
            saleItems: saleItems
        };
        localStorage.setItem('sale', JSON.stringify(newSale));
        navigate("/sale/confirm-items");
    }

    const onConfirm = async () => {
        setLoading(true);
        if(sale.customer === undefined) {
            setInputTextCustomerNameModalVisible(true);
        } else {
            doConfirm();
        }
        setLoading(false);
    }


    function changeProductNote(index) {
        setIndexProductNoteChanging(index);
        setInputTextProductNameModalVisible(true);
    }

    function onConfirmProductNote(note) {
        console.log("products2: " + JSON.stringify(products));
        const product = {...filteredProducts[indexProductNoteChanging]};
        const newNote = note.toUpperCase().trim();
        let currentNote = filteredProducts[indexProductNoteChanging].note;
        if(currentNote !== undefined) {
            currentNote = currentNote.toUpperCase().trim();
        } else {
            currentNote = '';
        }
        if (newNote !== currentNote) {
            let index = filteredProducts.findIndex(p => p.id === product.id && p.note === newNote);

            const newFilteredProducts = [...filteredProducts];

            const originalFilteredProduct = filteredProducts[indexProductNoteChanging];
            if (index === -1) {
                index = indexProductNoteChanging;
                const newProduct = {...product};
                if(newProduct.quantity === undefined || newProduct.quantity === 0 || product.note === undefined) {
                    newProduct.quantity = (originalFilteredProduct.quantity > 0? originalFilteredProduct.quantity : 1);
                    newProduct.note = newNote;
                    newFilteredProducts.splice(index, 0, {...newProduct});
                    newFilteredProducts[index + 1].quantity = 0;
                } else {
                    newFilteredProducts[index].note = newNote;
                }
            } else {
                newFilteredProducts[index].quantity = newFilteredProducts[index].quantity + (originalFilteredProduct.quantity > 0 ? originalFilteredProduct.quantity : 1);
                newFilteredProducts[indexProductNoteChanging].quantity = 0;
            }

            setFilteredProducts(newFilteredProducts);

            index = products.findIndex(p => p.id === product.id && p.note === newNote);
            const originalProductIndex = products.findIndex(p => p.id === product.id && (p.note  === currentNote || (p.note === undefined && currentNote === "") || (p.note === "" && currentNote === undefined)));

            const newProducts = [...products];
            const originalProduct = products[originalProductIndex];
            if (index === -1) {
                index = originalProductIndex;
                const newProduct = {...product};
                if(newProduct.quantity === undefined || newProduct.quantity === 0 || product.note === undefined) {
                    newProduct.quantity = (originalProduct.quantity > 0? originalProduct.quantity : 1);
                    newProduct.note = newNote;
                    newProducts.splice(index, 0, {...newProduct});
                    newProducts[index + 1].quantity = 0;
                } else {
                    newProducts[index].note = newNote;
                }
            } else {
                newProducts[index].quantity = newProducts[index].quantity + (originalProduct.quantity > 0 ? originalProduct.quantity : 1);
                newProducts[originalProductIndex].quantity = 0;
            }

            setProducts(newProducts);
        }
    }
    function onConfirmCustomerName(customerName) {
        sale.customerName = customerName;
        setSale(sale)
        doConfirm();
    }

    return (
        <>
            {
                inputTextProductNameModalVisible ? <TextInputModal
                        label={"Informe a observação para o item \r\n" + filteredProducts[indexProductNoteChanging].name + ":"}
                        value={filteredProducts[indexProductNoteChanging].note} onConfirm={onConfirmProductNote}
                        onClose={() => setInputTextProductNameModalVisible(false)} removeSpecialCharacters={true}/> :
                    inputTextCustomerNameModalVisible ? <TextInputModal
                            label={"Informe o nome do cliente:"}
                            value={(sale.customerName ? sale.customerName : '')}
                            onConfirm={onConfirmCustomerName}
                            messageWhenEmpty="Nome inválido!"
                            onClose={() => setInputTextCustomerNameModalVisible(false)}  removeSpecialCharacters={true}/> :
                    <>

                        <div
                            className="header-label">Pedido {customerName !== undefined ? customerName : 'Balcão'}</div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%'
                            }}
                            className="input-container">
                            <input style={{width: '100%'}} ref={inputQueryString} type="text" value={queryString}
                                   onChange={handleInputChange} onFocus={(event) => event.target.select()}/>
                            <button id="clearButton" onClick={() => {
                                setQueryString('');
                                inputQueryString.current.focus();
                            }}>X
                            </button>
                        </div>
                        <table style={{width: '100%'}}>
                            <colgroup>
                                <col style={{width: '300px'}}/>
                                <col style={{width: '50px'}}/>
                                <col style={{width: '50px'}}/>
                            </colgroup>
                            <thead>
                            <tr>
                                <th>
                                    Nome
                                </th>
                                <th>
                                    Preço
                                </th>
                                <th/>
                                <th/>
                                <th/>
                            </tr>
                            </thead>
                            <tbody style={{overflowY: 'auto'}}>
                            {filteredProducts.map((product, index) => (
                                <tr key={index}
                                    style={{backgroundColor: product.quantity > 0 ? 'var(--warning-color)' : product.quantity < 0 ? 'var(--error-color)' : 'white'}}>
                                    <td onClick={() => changeProductNote(index)}>
                                        <div
                                            key={index}>{`${product.name}${product.note !== undefined ? ` ${product.note}` : ``}`}</div>
                                    </td>
                                    <td>
                                        <div style={{textAlign: 'right'}}>{product.salePrice.toLocaleString('pt-BR', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                        })}
                                        </div>
                                    </td>
                                    <td>
                                        <button className="remove-button button-minus"
                                                onClick={async () => {
                                                    setProducts((prevItems) => {
                                                            const newProducts = prevItems.map((item, i) =>
                                                                item.id === product.id && (item.note === product.note) ? {
                                                                    ...item,
                                                                    quantity: (item.quantity ? item.quantity : 0) - 1,
                                                                } : item
                                                            );
                                                            const saleItems = products
                                                                .filter((p) => p.quantity !== 0)
                                                                .map((p) => {
                                                                    return {product: p, quantity: p.quantity};
                                                                });
                                                            setSale((prevSale) => {
                                                                prevSale.saleItems = saleItems;
                                                                return prevSale;
                                                            });
                                                            return newProducts;
                                                        }
                                                    );
                                                    setFilteredProducts((prevItems) =>
                                                        prevItems.map((item, i) =>
                                                            i === index ? {
                                                                ...item,
                                                                quantity: (item.quantity ? item.quantity : 0) - 1,
                                                            } : item
                                                        )
                                                    );
                                                }}>-
                                        </button>
                                    </td>
                                    <td>
                                        {product.quantity ? product.quantity : 0}
                                    </td>
                                    <td>
                                        <button className="button-plus" onClick={async () => {

                                            setProducts((prevItems) => {
                                                    const newProducts = prevItems.map((item, i) =>
                                                        item.id === product.id && (item.note === product.note) ? {
                                                            ...item,
                                                            quantity: (item.quantity ? item.quantity : 0) + 1,
                                                        } : item
                                                    );
                                                    const saleItems = products
                                                        .filter((p) => p.quantity > 0)
                                                        .map((p) => {
                                                            return {product: p, quantity: p.quantity};
                                                        });
                                                    setSale((prevSale) => {
                                                        prevSale.saleItems = saleItems;
                                                        return prevSale;
                                                    });
                                                    return newProducts;
                                                }
                                            );
                                            setFilteredProducts((prevItems) =>
                                                prevItems.map((item, i) =>
                                                    i === index ? {
                                                        ...item,
                                                        quantity: (item.quantity ? item.quantity : 0) + 1,
                                                    } : item
                                                )
                                            );


                                        }}>+
                                        </button>
                                    </td>

                                </tr>
                            ))}
                            </tbody>
                            {filteredProducts.length === 0 &&
                                <tfoot>
                                <tr>
                                    <td colSpan={5}>
                                        Nenhum item encontrado
                                    </td>
                                </tr>
                                </tfoot>
                            }
                        </table>
                        <div style={{marginTop: '5px', alignItems: 'center'}}>

                            <div className="total-label" style={{textAlign: 'right'}}>Valor Total:
                                R$ {totalValue.toLocaleString('pt-BR', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })}
                            </div>
                        </div>
                        <ActionButtonsManagerView>
                            <button onClick={onConfirm} disabled={loading}>Conferir</button>
                        </ActionButtonsManagerView>
                    </>
            }
        </>
    );
}

export default SaleManagerItems;
