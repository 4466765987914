import React, {useEffect, useRef, useState} from 'react';
import {findProductsByQueryString, insertSale} from "../../utils/requestUtils";
import Config from "../../core/config";
import {useRecoilState} from "recoil";
import {loadingAtom} from "../../atoms";
import Cookies from "js-cookie";
import {useNavigate} from "react-router-dom";
import ConfirmButton from "../common/ConfirmButton";
import ActionButtonsManagerView from "../common/ActionButtonsManagerView";
import {makeRequest} from "../../utils/httpRequest";
import {adjustDateToBrowserTimeZone, calculateDaysDifference, getOnlyDate} from "../../utils/utils";

const apiUrl = Config.apiUrl;
const SaleManagerItems = () => {
    const [products, setProducts] = useState([]);
    const [reload, setReload] = useState(false);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [loading, setLoading] = useRecoilState(loadingAtom);
    const navigate = useNavigate();
    const saleCookie = localStorage.getItem('sale');
    const newSale = {saleItems: []};
    const [sale, setSale] = useState(saleCookie ? JSON.parse(localStorage.getItem('sale')) : newSale);
    const customerName = sale.customerName !== undefined ? sale.customerName : sale.customer !== undefined ? sale.customer.name : undefined;
    const [totalValue, setTotalValue] = useState(0);

    async function makeQuery(query) {
        try {
            setLoading(true);
            let productWithDifferentNameToAdd = [];
            let products = await findProductsByQueryString(query);
            if (sale.saleItems !== undefined) {
                sale.saleItems.forEach((s) => {
                    for (let index = 0; index < products.length; index++) {
                        const p = products[index];
                        if (s.product.id === p.id && s.product.name === p.name)  {
                            if(s.product.note !== p.note) {
                                const newProduct = { ...p };
                                newProduct.note = s.product.note;
                                newProduct.quantity = s.quantity;
                                productWithDifferentNameToAdd = productWithDifferentNameToAdd.concat(newProduct);
                            } else {
                                p.quantity = s.quantity;
                            }

                        }
                    }
                });
            }


            for (let index = 0; index < productWithDifferentNameToAdd.length; index++) {
                const p = productWithDifferentNameToAdd[index];

                let indexToInsert = 0;
                for (let i = 0; i < products.length; i++) {
                    console.log(p.sort +' < '+ products[i].sort +' && '+p.name+' < '+ products[i].name);
                    if (p.sort <= products[i].sort && p.name.includes(products[i].name)) {
                        indexToInsert = i;
                        break;
                    } else {
                        indexToInsert = i + 1;
                    }
                }
                products.splice(indexToInsert, 0, p);


            }
            products = products.filter((p) => p.quantity > 0 || p.quantity < 0);
            setProducts(products);
            setFilteredProducts(JSON.parse(JSON.stringify(products)));
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    }

    useEffect(() => {
        setLoading(true);
        const newTotalValue = products.filter(p => p.quantity > 0 || p.quantity < 0).reduce((accumulator, currentProduct) => {
            const quantity = currentProduct.quantity ?? 0;
            return accumulator + (currentProduct.salePrice * quantity);
        }, 0);
        setTotalValue(newTotalValue);
        setLoading(false);

    }, [products]);

    useEffect(() => {
        makeQuery('');
    }, []);

    const doConfirm = async () => {
        const saleItems = products
            .filter((p) => p.quantity > 0 || p.quantity < 0)
            .map((p) => {
                return {product: p, productName: p.name + (p.note !== undefined ? ' ' + p.note : ''), quantity: p.quantity};
            });
        if (saleItems.length === 0) {
            alert('Nenhum item foi adicionado.')
            return;
        }

        /* eslint-disable no-restricted-globals */
        if (!confirm('Essa operação não poderá ser revertida. Deseja confinuar?')) {
            return;
        }

        sale.saleItems = saleItems;

        await insertSale(sale);

        localStorage.removeItem('sale');
        navigate('/app');
    };

    const onConfirm = async () => {
        setLoading(true);
        await doConfirm();
        setLoading(false);
    };


    return (
        <>
            <div className="header-label">Confirmar pedido {customerName !== undefined ? customerName : 'Balcão'}</div>
            <table style={{width: '100%'}}>
                <colgroup>
                    <col style={{width: '500px'}}/>
                    <col style={{width: '50px'}}/>
                    <col style={{width: '50px'}}/>
                </colgroup>
                <thead>
                <tr>
                    <th>
                        Nome
                    </th>
                    <th>
                        Qtd
                    </th>
                    <th>
                        Preço
                    </th>
                </tr>
                </thead>
                <tbody style={{overflowY: 'auto'}}>
                {filteredProducts.map((product, index) => (
                    <tr key={index} style={{backgroundColor: product.quantity > 0 ? 'var(--warning-color)' : product.quantity < 0 ? 'var(--error-color)' : 'white'}}>
                        <td>
                            <div key={index}>{product.name + (product.note !== undefined ? ' ' + product.note : '')}</div>
                        </td>
                        <td>
                            {product.quantity ? product.quantity : 0}
                        </td>
                        <td>
                            <div style={{textAlign: 'right'}}>{product.salePrice.toLocaleString('pt-BR', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}
                            </div>
                        </td>
                    </tr>
                ))}
                </tbody>
                {filteredProducts.length === 0 &&
                    <tfoot>
                    <tr>
                        <td colSpan={5}>
                            Nenhum item encontrado
                        </td>
                    </tr>
                    </tfoot>
                }
            </table>
            <div style={{marginTop: '5px', alignItems: 'center'}}>

                <div className="total-label" style={{textAlign: 'right', fontSize: '30px', color: '#cc5545'}}>Valor
                    Total:
                    R$ {totalValue.toLocaleString('pt-BR', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })}
                </div>
            </div>
            <ActionButtonsManagerView>
                <button onClick={onConfirm} style={{backgroundColor: '#cc5545'}} disabled={loading}>Confirmar</button>
            </ActionButtonsManagerView>
        </>
    );
}

export default SaleManagerItems;
