import React, {useState, useEffect, useRef} from 'react';
import {findCustomerPendingSale, findCustomersByQueryString} from "../../utils/requestUtils";
import Config from "../../core/config";
import {useRecoilState} from "recoil";
import {loadingAtom} from "../../atoms";
import {useNavigate} from "react-router-dom";
import Cookies from "js-cookie";
import ConfirmButton from "../common/ConfirmButton";
import ActionButtonsManagerView from "../common/ActionButtonsManagerView";

const apiUrl = Config.apiUrl;
const CustomerPendingSaleList = () => {
        const [customers, setCustomers] = useState([]);
        const [filteredCustomers, setFilteredCustomers] = useState([]);
        const [queryString, setQueryString] = useState('');
        const inputQueryString = useRef(null);
        const [, setLoading] = useRecoilState(loadingAtom);
        const navigate = useNavigate();

        async function makeQuery(query) {
            try {
                setLoading(true);
                const customers = await findCustomersByQueryString(query);
                customers.forEach(c => c.checked = false);
                setCustomers(customers);
                setFilteredCustomers(customers);
                setQueryString(query);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        }

        useEffect(() => {
            if (inputQueryString.current) {
                inputQueryString.current.focus();
            }
            makeQuery('');
        }, []);

        function removeSpecialChars(str) {
            return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
        }

        useEffect(() => {
            const filteredCustomer = customers.filter((f) => f.checked === true || removeSpecialChars(f.name).toUpperCase().includes(queryString));
            const sortedCustomers = getCustomersSortedByChecked(filteredCustomer);
            setFilteredCustomers(sortedCustomers);

            setFilteredCustomers(sortedCustomers);
            if (inputQueryString.current) {
                inputQueryString.current.focus();
            }
        }, [queryString]);
        const handleInputChange = (e) => {
            let string = removeSpecialChars(e.target.value).toUpperCase();
            setQueryString(string);
        };

        const onCheckBoxChange = (index) => {
            const updatedCustomers = [...filteredCustomers];
            updatedCustomers[index].checked = !updatedCustomers[index].checked;

            const sortedCustomers = getCustomersSortedByChecked(updatedCustomers);

            setFilteredCustomers(sortedCustomers);
            setQueryString('');

        };

        function getCustomersSortedByChecked(customers) {
            let sortedCustomers = customers.filter(c => c.checked === true);
            sortedCustomers = sortedCustomers.concat(customers.filter(c => c.checked === undefined || c.checked === false));
            return sortedCustomers;
        }

        function onInputQueryKeyDown(e) {
            if (e.key === 'Enter') {
                doConfirm();
            }
        }

        function doConfirm() {
            const idsString = customers.filter(c => c.checked === true).map(c => c.id).join(',');
            navigate(`/sale/finishitems/${idsString}`)
        }

        return (
            <>
                <div className="header-label">Fechar Comanda</div>

                <div
                    style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}
                    className="input-container">
                    <input style={{width: '100%'}} ref={inputQueryString} type="text" value={queryString}
                           onChange={handleInputChange} onFocus={(event) => event.target.select()} inputMode="numeric"
                           onKeyDown={onInputQueryKeyDown}/>
                    <button id="clearButton" onClick={() => {
                        setQueryString('');
                        inputQueryString.current.focus();
                    }}>X
                    </button>
                </div>
                <div style={{flexGrow: 1, overflowY: "auto"}}>
                    <table style={{width: '100%'}}>
                        <colgroup>
                            <col style={{width: '10px'}}/>
                            <col style={{width: '300px'}}/>
                            <col style={{width: '50px'}}/>
                        </colgroup>
                        <tbody style={{overflowY: 'auto'}}>
                        {filteredCustomers.map((customer, index) => (
                            <tr key={index} style={{backgroundColor: customer.checked ? 'var(--warning-color)' : 'white'}}>
                                <td>
                                    <input type="checkbox"
                                           checked={customer.checked}
                                           onChange={() => onCheckBoxChange(index)}/>
                                </td>
                                <td>
                                    <div key={index}>{customer.name}</div>
                                </td>
                                <td>
                                    {filteredCustomers.filter(c => c.checked === true).length === 0 &&
                                        <button onClick={() => {
                                            navigate('/sale/finishitems/' + customer.id);
                                        }}>Conferir
                                        </button>
                                    }
                                </td>

                            </tr>
                        ))}
                        </tbody>
                        {customers.length === 0 &&
                            <tfoot>
                            <tr>
                                <td colSpan={5}>
                                    Nenhum item encontrado
                                </td>
                            </tr>
                            </tfoot>
                        }
                    </table>
                </div>
                {customers.filter(c => c.checked === true).length > 0 &&
                    <ActionButtonsManagerView>
                        <button onClick={doConfirm}>Conferir
                        </button>
                    </ActionButtonsManagerView>
                }
            </>
        );
    }
;

export default CustomerPendingSaleList;
