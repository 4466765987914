import React, {useState, useEffect, useRef} from 'react';
import {findTablesByQueryString} from "../../utils/requestUtils";
import Config from "../../core/config";
import {useRecoilState} from "recoil";
import {loadingAtom} from "../../atoms";
import {useNavigate} from "react-router-dom";
import Cookies from "js-cookie";

const apiUrl = Config.apiUrl;
const SaleManagerTable = () => {
        const [tables, setTables] = useState([]);
        const [filteredTables, setFilteredTables] = useState([]);
        const [queryString, setQueryString] = useState('');
        const inputQueryString = useRef(null);
        const [, setLoading] = useRecoilState(loadingAtom);
        const navigate = useNavigate();

        async function makeQuery(query) {
            try {
                setLoading(true);
                const tables = await findTablesByQueryString(query);
                setTables(tables);
                setFilteredTables(tables);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        }

        useEffect(() => {
            if (inputQueryString.current) {
                inputQueryString.current.focus();
            }
            makeQuery('');
        }, []);

        useEffect(() => {
            const filteredTables = tables.filter((f) => removeSpecialChars(f.name).toUpperCase().includes(queryString));
            setFilteredTables(filteredTables);
        }, [queryString]);

        function removeSpecialChars(str) {
            return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
        }

        const handleInputChange = (e) => {
            const string = removeSpecialChars(e.target.value).toUpperCase();
            const filteredTables = tables.filter((f) => removeSpecialChars(f.name).toUpperCase().includes(string));
            setFilteredTables(filteredTables);
            setQueryString(string);
        };

        return (
            <>
                <div className="header-label">Mesa</div>

                <div
                    style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}
                    className="input-container">
                    <input style={{width: '100%'}} ref={inputQueryString} type="text" value={queryString}
                           onChange={handleInputChange} onFocus={(event) => event.target.select()} inputMode="numeric"/>
                    <button id="clearButton" onClick={() => {
                        setQueryString('');
                        inputQueryString.current.focus();
                    }}>X
                    </button>
                </div>
                <table style={{width: '100%'}}>
                    <colgroup>
                        <col style={{width: '500px'}}/>
                        <col style={{width: '50px'}}/>
                    </colgroup>
                    <thead>
                    <tr>
                        <th>
                            Nome
                        </th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody style={{overflowY: 'auto'}}>
                    {filteredTables.map((table, index) => (
                        <tr key={index}>
                            <td>
                                <div key={index}>{table.name}</div>
                            </td>
                            <td>
                                <button onClick={async () => {
                                    const sale = {table: table};
                                    localStorage.setItem('sale', JSON.stringify(sale));
                                    navigate("/sale/customer");
                                }}>Selecionar
                                </button>
                            </td>

                        </tr>
                    ))}
                    </tbody>
                    {tables.length === 0 &&
                        <tfoot>
                        <tr>
                            <td colSpan={5}>
                                Nenhum item encontrado
                            </td>
                        </tr>
                        </tfoot>
                    }
                </table>
            </>
        );
    }
;

export default SaleManagerTable;
