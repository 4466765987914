class HttpError extends Error {
    constructor(statusCode, message) {
        super(message);
        this.statusCode = statusCode;
        this.name = 'HttpError';
    }
}

export function makeRequest(url, method, headers, body, timeout = 5000, retries = 5) {
    const options = {
        method: method,
        headers: headers,
        body: body
    };

    let retryCount = 0;

    const fetchWithTimeout = (url, options) => {
        return Promise.race([
            fetch(url, options),
            new Promise((_, reject) =>
                setTimeout(() => reject(new Error('Request timed out')), timeout)
            ),
        ]);
    };

    const attemptRequest = () => {
        return fetchWithTimeout(url, options)
            .then(response => {
                if (response.status === 403) {
                    window.location.href = '/login';
                }
                if (!response.ok) {
                    throw new HttpError(response.status, `Request error: ${response.statusText}`);
                }
                if (method === 'DELETE') {
                    return response;
                }
                return response.json();
            })
            .catch(error => {
                retryCount++;
                if (retryCount < retries) {
                    console.log(`Retrying (${retryCount}/${retries})...`);
                    return attemptRequest();
                } else {
                    console.error('Request error:', error);
                    throw error;
                }
            });
    };

    return attemptRequest();
}