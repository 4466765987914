import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
);

const colors = [
    'rgba(255, 99, 132, 0.4)', 'rgba(54, 162, 235, 0.4)', 'rgba(255, 206, 86, 0.4)',
    'rgba(75, 192, 192, 0.4)', 'rgba(153, 102, 255, 0.4)', 'rgba(255, 159, 64, 0.4)',
    'rgba(199, 199, 199, 0.4)', 'rgba(83, 102, 255, 0.4)', 'rgba(140, 159, 64, 0.4)',
    'rgba(255, 142, 207, 0.4)', 'rgba(100, 102, 255, 0.4)', 'rgba(200, 206, 86, 0.4)',
    'rgba(125, 192, 192, 0.4)', 'rgba(153, 122, 255, 0.4)', 'rgba(255, 139, 64, 0.4)',
    'rgba(199, 189, 199, 0.4)', 'rgba(83, 112, 255, 0.4)', 'rgba(140, 159, 94, 0.4)',
    'rgba(255, 132, 207, 0.4)', 'rgba(100, 142, 255, 0.4)', 'rgba(200, 126, 86, 0.4)',
    'rgba(125, 102, 192, 0.4)', 'rgba(153, 112, 255, 0.4)', 'rgba(255, 159, 104, 0.4)',
    'rgba(199, 179, 199, 0.4)', 'rgba(83, 132, 255, 0.4)', 'rgba(140, 129, 64, 0.4)',
    'rgba(255, 172, 207, 0.4)', 'rgba(100, 152, 255, 0.4)', 'rgba(200, 176, 86, 0.4)',
    'rgba(125, 142, 192, 0.4)', 'rgba(153, 132, 255, 0.4)', 'rgba(255, 149, 64, 0.4)',
    'rgba(199, 109, 199, 0.4)', 'rgba(83, 182, 255, 0.4)', 'rgba(140, 119, 94, 0.4)',
    'rgba(255, 102, 207, 0.4)', 'rgba(100, 102, 205, 0.4)', 'rgba(200, 226, 86, 0.4)',
    'rgba(125, 182, 192, 0.4)', 'rgba(153, 102, 155, 0.4)', 'rgba(255, 159, 94, 0.4)',
    'rgba(199, 199, 189, 0.4)', 'rgba(83, 152, 255, 0.4)', 'rgba(140, 189, 64, 0.4)',
    'rgba(255, 132, 107, 0.4)', 'rgba(100, 192, 255, 0.4)', 'rgba(200, 176, 86, 0.4)',
    'rgba(125, 192, 142, 0.4)', 'rgba(153, 152, 225, 0.4)'
];

const BarChart = ({ labels, dataValues }) => {
    const backgroundColors = labels.map((_, index) => colors[index % colors.length]);

    const data = {
        labels: labels,
        datasets: [
            {
                data: dataValues,
                backgroundColor: backgroundColors,
                categoryPercentage: 0.8,
                barPercentage: 0.9,
            },
        ],
    };

    const options = {
        indexAxis: 'y',
        scales: {
            y: {
                display: true,
                grid: {
                    display: false, // Exibe as linhas de grade para o eixo y
                },
                ticks: {
                    color: 'black',
                },
            },
            x: {
                display: true, // Exibe o eixo x
                grid: {
                    display: true, // Exibe as linhas de grade para o eixo x
                },
                min: 0, // Ajuste o valor mínimo para 0
                ticks: {
                    color: 'black',
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: true, // Habilita o tooltip
                mode: 'nearest',
                intersect: false,
                callbacks: {
                    label: (context) => {
                        const value = context.raw;
                        return `R$ ${value.toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2})}`;
                    }
                }
            },
            datalabels: {
                display: false, // Desabilita a exibição dos rótulos
            },
        },
    };



    return <Bar data={data} options={options} />;
};

export default BarChart;
