import React, {useState, useEffect, useRef} from 'react';
import {makeRequest} from "../../utils/httpRequest";
import Config from "../../core/config";
import {useRecoilState} from "recoil";
import {loadingAtom} from "../../atoms";
import Cookies from "js-cookie";
import {useNavigate} from "react-router-dom";
import {findCustomersByQueryString} from "../../utils/requestUtils";

const apiUrl = Config.apiUrl;
const CustomerList = () => {
    let [customers, setCustomers] = useState([]);
    const [queryString, setQueryString] = useState('');
    const inputQueryString = useRef(null);
    const [, setLoading] = useRecoilState(loadingAtom);
    const navigate = useNavigate();

    async function makeQuery(query) {
        try {
            setLoading(true);
            const response = await findCustomersByQueryString(query);
            setCustomers(response);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (inputQueryString.current) {
            inputQueryString.current.focus();
        }
        makeQuery('');
    }, []); // Empty dependency array ensures that useEffect runs only once, similar to componentDidMount

    async function excluirCliente(id) {
        try {
            const url = apiUrl + '/api/customers/' + id;
            await makeRequest(url, 'DELETE', {'Useruuid':Cookies.get('userUUID')});
            await makeQuery(queryString);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const handleInputChange = (e) => {
        const string = e.target.value;
        if (string.length >= 3 || string.length === 0) {
            makeQuery(string);
        }
        setQueryString(string);
    };

    return (
        <>
            <div className="header-label">Consulta de Clientes</div>

            <div
                style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
                <input ref={inputQueryString} type="text" value={queryString} onChange={handleInputChange}/>
                <button onClick={() => navigate('/customer')}>Novo</button>
            </div>

            <table style={{width: '580px'}}>

                <tr>
                    <th>
                        Código
                    </th>
                    <th>
                        Nome
                    </th>
                    <th/>
                    <th/>
                    <th/>
                </tr>
                <tbody style={{overflowY: 'auto'}}>

                {customers.map((customer, index) => (


                    <tr>
                        <td>
                            <div key={index}>{customer.code}</div>
                        </td>
                        <td>
                            <div key={index}>{customer.name}</div>
                        </td>
                        <td>
                            <button onClick={async () => {
                                await navigate('/customer?id='+customer.id);
                            }}>Editar
                            </button>
                        </td>
                        <td>
                            <button className="remove-button" onClick={async () => {
                                // eslint-disable-next-line no-restricted-globals
                                if (window.confirm('Deseja realmente excluir?')) {
                                    await excluirCliente(customer.id);
                                }
                            }}>Excluir
                            </button>
                        </td>

                    </tr>
                ))}
                </tbody>
                {customers.length === 0 && (<tfoot>Nenhum item encontrado.</tfoot>)}
            </table>


        </>
    );
};

export default CustomerList;
