import React, {useEffect, useState} from 'react';
import '../css/Loading.css'

const Loading = () => {
    const [dot1, setDot1] = useState(true);
    const [dot2, setDot2] = useState(false);
    const [dot3, setDot3] = useState(false);
    const [dot, setDot] = useState(1);

    useEffect(() => {
        const interval = setInterval(() => {
            setDot((prev) => {
                if (prev < 3) return (prev + 1); else return 1;
            });

        }, 500); // Altere a velocidade conforme necessário (aqui é 500ms)

        return () => clearInterval(interval);
    }, []);

    useEffect(()=>{

        if (dot === 1) {
            setDot1(true);
            setDot2(false);
            setDot3(false);
        }

        if (dot === 2) {
            setDot1(false);
            setDot2(true);
            setDot3(false);
        }

        if (dot === 3) {
            setDot1(false);
            setDot2(false);
            setDot3(true);
        }
    }, [dot]);

    return (
        <div className="loading-overlay">
            <div className="loading-spinner">
                <div className={`dot ${dot1 ? 'active' : ''}`}></div>
                <div className={`dot ${dot2 ? 'active' : ''}`}></div>
                <div className={`dot ${dot3 ? 'active' : ''}`}></div>
            </div>
        </div>
    );
};


export default Loading;
