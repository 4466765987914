import React, {useState, useEffect, useRef} from 'react';
import {makeRequest} from "../../utils/httpRequest";
import ProductSelect from "./ProductSelect";
import ProductAvailability from "./ProductAvailability";
import {findProductsByQueryString} from "../../utils/requestUtils";
import Config from "../../core/config";
import {useRecoilState} from "recoil";
import {loadingAtom} from "../../atoms";
import Cookies from "js-cookie";
import {useNavigate} from "react-router-dom";

const apiUrl = Config.apiUrl;
const ProductList = () => {
        let [products, setProducts] = useState([]);
        const [queryString, setQueryString] = useState('');
        const inputQueryString = useRef(null);
        const [, setLoading] = useRecoilState(loadingAtom);
        const navigate = useNavigate();

        async function makeQuery(query) {
            try {
                setLoading(true);
                setProducts(await findProductsByQueryString(query));
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        }

        useEffect(() => {
            if (inputQueryString.current) {
                inputQueryString.current.focus();
            }
            makeQuery('');
        }, []);

        async function excluirProduto(id) {
            try {
                const url = apiUrl + '/api/products/' + id;
                await makeRequest(url, 'DELETE', {'Useruuid':Cookies.get('userUUID')});
                await makeQuery(queryString);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

        const handleInputChange = (e) => {
            const string = e.target.value;
            if (string.length >= 3 || string.length === 0) {
                makeQuery(string);
            }
            setQueryString(string);
        };


        return (
            <>
                <div className="header-label">Consulta de Produtos</div>

                <div
                    style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
                    <input ref={inputQueryString} type="text" value={queryString} onChange={handleInputChange}/>
                    <button onClick={() => navigate('/product')}>Novo</button>                </div>
                <table style={{width: '600px'}}>
                    <tr>
                        <th>
                            Código
                        </th>
                        <th>
                            Nome
                        </th>
                        <th>
                            Categoria
                        </th>
                        <th>
                            Preço de Venda
                        </th>
                        <th/>
                        <th/>
                    </tr>
                    <tbody style={{overflowY: 'auto'}}>
                    {products.map((product, index) => (


                        <tr>
                            <td>
                                <div key={index}>{product.code}</div>
                            </td>
                            <td>
                                <div key={index}>{product.name}</div>
                            </td>
                            <td>
                                <div key={index}>{product.category}</div>
                            </td>
                            <td>
                                <div style={{textAlign: 'right'}}>{product.salePrice.toLocaleString('pt-BR', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })}
                                </div>
                            </td>
                            <td>
                                <button onClick={async () => {
                                    await navigate('/product?id='+product.id);
                                }}>Editar
                                </button>
                            </td>
                            <td>
                                <button className="remove-button" onClick={async () => {
                                    // eslint-disable-next-line no-restricted-globals
                                    if (window.confirm('Deseja realmente excluir?')) {
                                        await excluirProduto(product.id);
                                    }
                                }}>Excluir
                                </button>
                            </td>

                        </tr>
                    ))}
                    </tbody>
                    {products.length === 0 && (<tfoot>Nenhum item encontrado.</tfoot>)}
                </table>
            </>
        );
    }
;

export default ProductList;
